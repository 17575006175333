export default defineNuxtRouteMiddleware(async () => {
  // Getting the user data
  const user = useSupabaseUser()

  // Setting the user on Sentry
  const { $Sentry } = useNuxtApp()
  $Sentry?.setUser(user.value)

  // Redirecting the user if needed
  if (!user.value) {
    return navigateTo('/login')
  }
})